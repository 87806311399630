var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('v-card',{class:['card', `card--${_vm.$vuetify.breakpoint.name}`],style:({
    backgroundImage: `url(${_vm.bgImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    display: _vm.flex, justify: _vm.center
  })},[_c('v-container',{attrs:{"fluid":""}},[_c('v-card-title',{class:{
        'card-title-xs': _vm.$vuetify.breakpoint.xsOnly,
        'card-title-sm': _vm.$vuetify.breakpoint.smAndUp,
        'card-title-md': _vm.$vuetify.breakpoint.mdAndUp,
      },staticStyle:{"color":"whitesmoke"}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('span',[_c('Strong',[_vm._v("Peanut Foods Manufacturing Company")])],1):_vm._e(),(_vm.$vuetify.breakpoint.xs)?_c('span',[_c('Strong',[_vm._v("Peanut Foods Manufacturing ")])],1):_vm._e()]),_c('v-divider',{attrs:{"height":"5px","color":"dimgrey"}}),_c('v-card-text',{class:{
        'card-text-xs': _vm.$vuetify.breakpoint.xsOnly,
        'card-text-sm': _vm.$vuetify.breakpoint.smAndUp,
        'card-text-md': _vm.$vuetify.breakpoint.mdAndUp,
        'card-text-lg': _vm.$vuetify.breakpoint.lgAndUp
      },staticStyle:{"color":"wheat"}},[_c('span',[_vm._v("Enjoy the most delicious and creamiest "),_c('Strong',[_vm._v("Enat Peanut Butter.")]),_vm._v(" It is all-natural peanut butter.")],1)])],1),_c('v-divider',{attrs:{"height":"5px","color":"dimgrey"}})],1),_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"1250","flat":""}},[_c('v-divider',{attrs:{"height":"5px","color":"dimgrey"}}),_c('v-card',{attrs:{"flat":"","height":"20px"}}),_c('h3',{staticStyle:{"color":"dimgray"}},[_vm._v("Our Vision")]),_c('v-card',{attrs:{"flat":"","height":"2px"}}),_c('p',[_vm._v("We envision to be one of the top peanut foods manufacturing companies in the Horn of Africa. For the last five years, our company has brought innovation, branding and outstanding value to our consumers, customers and the community. We have been proud of our achievements so far. Also, we are more motivated to set the standard in food processing in this regard. Our hardworking experts are our pride! ")]),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.bodyImages),function(bodyImage){return _c('v-col',{key:bodyImage.title,attrs:{"cols":"12","xs":"12","sm":"6","md":"4","lg":"3"}},[_c('v-card',[_c('v-img',{staticClass:"align-end",attrs:{"src":bodyImage.src,"gradient":"to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)","height":"220px","cover":""}}),_c('P',{staticClass:"text-8 text-italic text-center"},[_vm._v(" "+_vm._s(bodyImage.title))])],1)],1)}),1)],1)],1),_c('v-card',{class:['card', `card--${_vm.$vuetify.breakpoint.name}`],style:({
    backgroundImage: `url(${_vm.bgImage1})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '90%',
    display: _vm.flex, justify: _vm.center
  }),attrs:{"height":"500px"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-card-title',{class:{
        'card-title-xs': _vm.$vuetify.breakpoint.xsOnly,
        'card-title-sm': _vm.$vuetify.breakpoint.smAndUp,
        'card-title-md': _vm.$vuetify.breakpoint.mdAndUp,
      },staticStyle:{"color":"whitesmoke"}}),_c('v-divider',{attrs:{"height":"5px","color":"dimgrey"}}),_c('v-card-text',{class:{
        'card-text-xs': _vm.$vuetify.breakpoint.xsOnly,
        'card-text-sm': _vm.$vuetify.breakpoint.smAndUp,
        'card-text-md': _vm.$vuetify.breakpoint.mdAndUp,
        'card-text-lg': _vm.$vuetify.breakpoint.lgAndUp
      },staticStyle:{"color":"#6200ea"}},[_c('span',{staticStyle:{"font-size":"28px"}},[_c('Strong',[_vm._v("Conformity Assessment by Ethiopian Food and Drug Authority")])],1)])],1),_c('v-divider',{attrs:{"height":"5px","color":"dimgrey"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }