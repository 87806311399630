var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('v-card',{class:['card', `card--${_vm.$vuetify.breakpoint.name}`],style:({
    backgroundImage: `url(${_vm.bgImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    display: _vm.flex, justify: _vm.center
  })},[_c('v-container',{attrs:{"fluid":""}},[_c('v-card-title',{class:{
        'card-title-xs': _vm.$vuetify.breakpoint.xsOnly,
        'card-title-sm': _vm.$vuetify.breakpoint.smAndUp,
        'card-title-md': _vm.$vuetify.breakpoint.mdAndUp,
      },staticStyle:{"color":"whitesmoke"}},[_c('span',[_c('Strong',[_vm._v("Our Products and Brands")])],1)])],1),_c('v-divider',{attrs:{"height":"5px","color":"dimgrey"}})],1),_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"1250","flat":""}},[_c('v-divider',{attrs:{"height":"5px","color":"dimgrey"}}),_c('v-card',{attrs:{"flat":"","height":"20px"}}),_c('v-card',{attrs:{"flat":"","height":"2px"}}),_c('p',{staticStyle:{"font-size":"22px"}},[_vm._v("Our Products are Organic, Flavorful and Nutritious! "),_c('br'),_vm._v(" "),_c('Strong',[_vm._v("Quality is our Motto! ")])],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.bodyImages),function(bodyImage){return _c('v-col',{key:bodyImage.title,attrs:{"cols":"12","xs":"12","sm":"6","md":"4","lg":"3"}},[_c('v-card',[_c('v-img',{staticClass:"align-end",attrs:{"src":bodyImage.src,"gradient":"to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)","height":"250px","cover":""}}),_c('P',{staticClass:"text-8 text-italic text-center"},[_vm._v(" "+_vm._s(bodyImage.title))])],1)],1)}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }